@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/eot/iranyekanwebthinfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebthinfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebthinfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebThinfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebthinfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/eot/iranyekanweblightfanum.eot');
  src: url('./assets/fonts/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanweblightfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebLightfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanweblightfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/eot/iranyekanwebregularfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebregularfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebRegularfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebregularfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/eot/iranyekanwebmediumfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebmediumfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebMediumfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/eot/iranyekanwebboldfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebboldfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebBoldfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebboldfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/eot/iranyekanwebextraboldfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebextraboldfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebextraboldfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebExtraBoldfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebextraboldfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/eot/iranyekanwebblackfanum.eot');
  src: url('./assets/fonts/eot/iranyekanwebblackfanum.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/woff/iranyekanwebblackfanum.woff') format('woff'),
    url('./assets/fonts/woff2/IRANYekanWebBlackfanum.woff2') format('woff2'),
    url('./assets/fonts/ttf/iranyekanwebblackfanum.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: iranyekan;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearHighLight {
  -webkit-tap-highlight-color: transparent;
}

button,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

button:disabled {
  opacity: 0.5;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.no-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  border-radius: 10px;
}

.no-scroll::-webkit-scrollbar {
  width: 5px;
}

.no-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.no-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.Toastify__toast {
  font-family: iranyekan !important;
  font-size: 14px;
}

.leaflet-div-icon {
  border: none !important;
  background: transparent !important;
}

/****** swipe button*********/

.styles_ReactSwipeButton__2WML2 {
  height: 40px !important;
}

.styles_rsbContainer__KXpXK {
  background: var(--color-captionLight) !important;
}

.styles_rsbcSlider__1yYxD {
  height: 40px !important;
}

.styles_rsbcSliderText__3h08j {
  height: 40px !important;
  line-height: 40px !important;
  font-family: iranyekan !important;
}

.styles_rsbcSliderCircle__3G0Zs {
  height: 40px !important;
  width: 70px !important;
}

.styles_rsbcText__16wA0 {
  height: 40px !important;
  line-height: 40px !important;
  font-family: iranyekan !important;
}

/****** swipe button*********/

/****** modal *********/
@keyframes activeScale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes activeFull {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.confirm-pan {
  transition-delay: 2s;
  transform: scale(0);
  transition: all ease 0.9s;
}

.confirm-pan.active {
  animation: activeScale 0.2s forwards;
}

.full-modal {
  transition-delay: 2s;
  transform: translateY(100%);
  transition: all linear 0.9s;
}

.full-modal.active {
  animation: activeFull 0.4s forwards;
}

/****** modal *********/

/****** swipeable *********/

.swipeable-list-item {
  border-radius: 100px;
}

.swipeable-list-item__content {
  height: 40px !important;
  background: var(--color-captionLight) !important;
  border-radius: 100px !important;
}

.swipeRight .swipeable-list-item__content {
  justify-content: end !important;
}

.swipeleft .swipeable-list-item__content {
  justify-content: start !important;
}

.swipeable-list-item__content-right,
.swipeable-list-item__content-left {
  opacity: 1 !important;
}

.basic-swipeable-list__item-content {
  background-color: var(--color-primary);
  height: 100%;
  width: 100%;
}

/****** swipeable *********/